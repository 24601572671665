<template>
  <div>
  <div class="leftSubmenu">
     <el-menu
      default-active="1"
      class="subdatamenu"
      @open="handleOpen"
      text-color="#333333"
      >

      <el-submenu v-for="(item, index) in submenu" :index="String(index+1)" :key="index">
        <template slot="title">
          <i :class="iconArr[index]"></i>
          <span>{{item.name}}</span>
        </template>
          <el-menu-item v-for="(items, indexs) in item.children"  :key="indexs" :index="String((index+1)+'-'+(indexs+1))" @click="gotoIframe(items.url)">{{items.name}}</el-menu-item>
      </el-submenu>

     </el-menu>
  </div>
  <div class="rightSubmenu">
  <iframe width="100%" :height="height" border="0" :src="tokenUrl" frameborder="0" align="center"
   scrolling="no"
   @onLoad="frameOnload"></iframe>
  </div>
  </div>
</template>

<script>
import { getTokens, getMenuOfChildren} from "@/api/datas";
export default {
  name: 'DataAnalyse',
  data() {
    return {
      tokenUrl:'',
      height: '1000',
      submenu: [],
      iconArr: ['el-icon-data-line', 'el-icon-pie-chart', 'el-icon-data-analysis', 'el-icon-collection-tag', 'el-icon-data-board', 'el-icon-tickets']
    }
  },
 
  methods: {
    frameOnload() {
      this.height = document.documentElement.clientHeight + 20;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    getTokenss(){
      var that = this
      getTokens().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tokenUrl = 'https://bi.kaituanyi.com/home.html?' + res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    gotoIframe(url){
      var that = this
      that.tokenUrl = url
    },
    getMenus(){
      var that = this
      var objs = {
        role: window.sessionStorage.getItem('role')
      }
      getMenuOfChildren(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.submenu = res.data.menus
          that.tokenUrl = res.data.menus[0].children[0].url
        }else{
          this.$message.error(res.message)
        }
      })
    }
  },
  mounted() {
    // this.getTokenss()
    this.getMenus()
  }
}
</script>

<style type="text/css" scoped>
.el-menu{
  border:none;
  font-weight: 500;
}
.subdatamenu .el-submenu{
  border-bottom: 1px solid #f3f3f3
}
.subdatamenu .el-submenu__title span{
  color: #333;
} 
.subdatamenu .el-submenu__title i{
  color: #333;
}
.leftSubmenu{
  position: fixed;
  left: 160px;
  top: 55px;
  bottom: 0;
  height: 100vh;
  width: 160px;
  background-color: #fff;

  display: block;
  z-index: 90;
  overflow: hidden;
}
.leftSubmenu ol{
  line-height:44px;
  height: 44px;font-size: 13px;
  color: #535353;
  display: block;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #eee;

}
.rightSubmenu{
  margin-left: 160px;
  display: block;
}
</style>